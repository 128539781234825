import React, { Suspense } from 'react'
import type { AppProps } from 'next/app'
import { ApolloProvider } from '@apollo/client'
import { Toaster } from 'react-hot-toast'
import { Analytics } from '@vercel/analytics/react'
import { SpeedInsights } from '@vercel/speed-insights/next'
import { GoogleTagManager } from '@next/third-parties/google'
import { UserSessionProvider } from '@web-app/context/UserSessionContext'
import { BranchProvider } from '@web-app/context/BranchContext'
import { BrazeProvider } from '@web-app/context/BrazeContext'
import { EventTrackingProvider } from '@web-app/context/EventTrackingContext'
import { useApolloClient } from '@web-app/hooks/useApolloClient'
import { inter, montserrat } from '@web-app/components/Fonts'
import ErrorBoundary from '@web-app/components/ErrorBoundary'
import type { NormalizedCacheObject } from '@apollo/client'

// Load Tailwind CSS
import '@web-app/styles/tailwind.css'

function WebApp({ Component, pageProps }: AppProps) {
  return (
    <ErrorBoundary>
      <UserSessionProvider>
        <Suspense fallback={<Component {...pageProps} />}>
          <AppProviders pageProps={pageProps}>
            <Component {...pageProps} />
          </AppProviders>
        </Suspense>
      </UserSessionProvider>
    </ErrorBoundary>
  )
}

export interface PageProps {
  initialApolloState?: NormalizedCacheObject
  [key: string]: unknown
}

interface AppProvidersProps {
  children: React.ReactNode
  pageProps: PageProps
}

const AppProviders: React.FC<AppProvidersProps> = ({ children, pageProps }) => {
  const apolloClient = useApolloClient(
    pageProps.initialApolloState || {},
    pageProps.browserFingerprint as string,
    pageProps.sessionId as string,
    pageProps.userAgent as string
  )

  return (
    <BranchProvider>
      <BrazeProvider>
        <ApolloProvider client={apolloClient}>
          <EventTrackingProvider
            browserFingerprint={pageProps.browserFingerprint as string}
            sessionId={pageProps.sessionId as string}
            userAgent={pageProps.userAgent as string}
          >
            <div
              className={`${inter.variable} ${montserrat.variable} font-inter`}
            >
              {children}
            </div>
            <Toaster position="top-center" />
            <Analytics />
            <SpeedInsights />
            <GoogleTagManager gtmId={process.env.NEXT_PUBLIC_GTM_ID || ''} />
          </EventTrackingProvider>
        </ApolloProvider>
      </BrazeProvider>
    </BranchProvider>
  )
}

export default WebApp
