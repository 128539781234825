import { Component, ErrorInfo, ReactNode } from 'react'
import { Notifier } from '@airbrake/browser'

interface ErrorBoundaryProps {
  children?: ReactNode
}

interface ErrorBoundaryState {
  hasError: boolean
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  private airbrake: Notifier

  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = { hasError: false }
    this.airbrake = new Notifier({
      projectId: 565768,
      projectKey: '84979e70ec744d854e3d173270c07388',
      environment: process.env.NODE_ENV || 'development',
    })

    // Ignore errors from Google bots
    this.airbrake.addFilter((notice) => {
      const userAgent = navigator.userAgent
      if (
        /AdsBot-Google-Mobile|Googlebot|AdsBot-Google|Google-Read-Aloud/.test(
          userAgent
        )
      ) {
        return null
      }
      return notice
    })
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    console.error({ error, info })
    this.setState({ hasError: true })
    this.airbrake.notify({
      error: error,
      params: { info: info },
    })
  }

  render() {
    // TODO: Add a fallback UI
    // if (this.state.hasError) {
    //   return <h1>Something went wrong.</h1>
    // }
    return this.props.children || null
  }
}

export default ErrorBoundary
