import React, { createContext, useEffect, useRef } from 'react'
import { useSession } from '@web-app/hooks/useSession'
import type { Branch } from '@web-app/types'

interface BranchEventProperties {
  [key: string]: unknown
}

interface BranchContextType {
  publishBranchEvent: (
    eventName: string,
    eventProperties?: BranchEventProperties
  ) => void
  trackPageView: () => void
}

export const BranchContext = createContext<BranchContextType | undefined>(
  undefined
)

export const BranchProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { sessionData } = useSession()
  const user = sessionData?.user
  const branchRef = useRef<Branch | null>(null)

  const initializeBranch = () => {
    if (!branchRef.current) {
      if (typeof window !== 'undefined' && window.branch) {
        branchRef.current = window.branch
      } else {
        console.error('Branch SDK not loaded')
      }
    }
  }

  useEffect(() => {
    initializeBranch()
  }, [])

  useEffect(() => {
    if (user?.id) {
      initializeBranch()
      branchRef.current?.setIdentity(user.id)
    }
  }, [user?.id])

  function publishBranchEvent(
    eventName: string,
    eventProperties?: BranchEventProperties
  ): void {
    initializeBranch()
    if (branchRef.current) {
      branchRef.current.logEvent(eventName, eventProperties)
    }
  }

  function trackPageView() {
    initializeBranch()
    if (branchRef.current) {
      branchRef.current.track('pageview')
    }
  }

  return (
    <BranchContext.Provider
      value={{
        publishBranchEvent,
        trackPageView,
      }}
    >
      {children}
    </BranchContext.Provider>
  )
}
