import { useContext } from 'react'
import {
  UserSessionContext,
  type UserSessionContextType,
} from '@web-app/context/UserSessionContext'

export const useUserSession = () => {
  const context = useContext(UserSessionContext)
  if (!context) {
    throw new Error('useUserSession must be used within a UserSessionProvider')
  }
  return context
}

export const useSession = () => {
  const { sessionData, setSessionData }: UserSessionContextType =
    useUserSession()

  return {
    sessionData,
    setSessionData,
  }
}
