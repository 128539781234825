import React, { createContext } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { useApolloClient } from '@web-app/hooks/useApolloClient'
import { useSession } from '@web-app/hooks/useSession'
import { useBranch } from '@web-app/hooks/useBranch'
import { useBraze } from '@web-app/hooks/useBraze'
import { sendGTMEvent } from '@next/third-parties/google'
import UAParser from 'ua-parser-js'
import {
  usePublishWebEventMutation,
  PublishWebEventInput,
} from '@libs/graphql/generated/react-apollo'
import type { Token } from '@web-app/lib/ironSession'

type EventData = Record<string, unknown>

type EventTrackingContextType = {
  trackEvent: (
    eventName: string,
    data?: EventData,
    keyEvent?: boolean
  ) => Promise<void>
}

export const EventTrackingContext = createContext<
  EventTrackingContextType | undefined
>(undefined)

export const EventTrackingProvider: React.FC<{
  children: React.ReactNode
  browserFingerprint: string
  sessionId: string
  userAgent: string
}> = ({ children, browserFingerprint, sessionId, userAgent }) => {
  const { sessionData } = useSession()
  const { user, utmParams } = sessionData
  const apolloClient = useApolloClient(
    {},
    browserFingerprint,
    sessionId,
    userAgent
  )

  const uaParser = new UAParser(userAgent)
  const uaResult = uaParser.getResult()

  const token = user?.token as Token
  const authHeaders = token
    ? { Authorization: `Bearer ${token.replace('Bearer ', '')}` }
    : {}

  const { publishBranchEvent } = useBranch()
  const { publishBrazeEvent } = useBraze()
  const [publishWebEvent] = usePublishWebEventMutation({
    client: apolloClient,
    context: {
      headers: {
        ...authHeaders,
      },
    },
  })

  const trackEvent = async (
    eventName: string,
    data: EventData = {},
    keyEvent = false
  ) => {
    const input: PublishWebEventInput = {
      clientMutationId: uuidv4(),
      event: {
        name: eventName,
        data: {
          ...data,
          ...utmParams,
          ...uaResult,
          browserFingerprint,
          sessionId,
        },
        requestId: uuidv4(),
      },
    }

    try {
      publishWebEvent({ variables: { input } })

      if (keyEvent) {
        publishBranchEvent(eventName, data)
        publishBrazeEvent(eventName, data)
        sendGTMEvent({ event: eventName, ...data })
      }
    } catch (error) {
      console.error(
        `Failed to track event: ${eventName}, Error Details: ${JSON.stringify(
          error,
          null,
          2
        )}`
      )
    }
  }

  return (
    <EventTrackingContext.Provider value={{ trackEvent }}>
      {children}
    </EventTrackingContext.Provider>
  )
}
