import React, {
  createContext,
  useEffect,
  useState,
  startTransition,
} from 'react'
import type { SessionData } from '@web-app/lib/ironSession'

const defaultContextValue: SessionData = {
  browserFingerprint: '',
  isAuthenticated: false,
}

export type UserSessionContextType = {
  sessionData: SessionData
  loading: boolean
  refetchSessionData: () => void
  setSessionData: (sessionData: SessionData) => Promise<void>
}

export const UserSessionContext = createContext<UserSessionContextType>({
  sessionData: defaultContextValue,
  loading: true,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  refetchSessionData: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setSessionData: async () => {},
})

export const UserSessionProvider: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const [sessionData, setSessionDataState] =
    useState<SessionData>(defaultContextValue)
  const [loading, setLoading] = useState(true)

  const setSessionData = (data: SessionData): Promise<void> => {
    return new Promise((resolve) => {
      startTransition(() => {
        setSessionDataState(data)
      })
      resolve()
    })
  }

  const refetchSessionData = async () => {
    setLoading(true)
    try {
      const response = await fetch('/api/user')
      const data = await response.json()
      await setSessionData(data)
    } catch (error) {
      console.error(`Failed to fetch session data: ${String(error)}`)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    startTransition(() => {
      refetchSessionData()
    })
  }, [])

  return (
    <UserSessionContext.Provider
      value={{
        sessionData,
        loading,
        refetchSessionData,
        setSessionData,
      }}
    >
      {children}
    </UserSessionContext.Provider>
  )
}
