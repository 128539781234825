import { useSession } from '@web-app/hooks/useSession'
import { initializeApollo } from '@web-app/lib/apolloClient'
import type { NormalizedCacheObject } from '@apollo/client'
import type { Token, SessionData } from '@web-app/lib/ironSession'

export const useApolloClient = (
  initialState: NormalizedCacheObject = {},
  browserFingerprint: string,
  sessionId: string,
  userAgent: string
) => {
  const { sessionData } = useSession()
  const { user } = sessionData as SessionData
  const token = user?.token as Token

  return initializeApollo(
    initialState,
    browserFingerprint,
    sessionId,
    userAgent,
    token
  )
}
