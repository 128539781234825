import React, { createContext, useEffect, useRef } from 'react'
import { useSession } from '@web-app/hooks/useSession'

interface BrazeEventProperties {
  [key: string]: unknown
}

interface BrazeContextType {
  publishBrazeEvent: (
    eventName: string,
    properties?: BrazeEventProperties
  ) => void
}

export const BrazeContext = createContext<BrazeContextType | undefined>(
  undefined
)

export const BrazeProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { sessionData } = useSession()
  const user = sessionData?.user
  const brazeIsLoadedRef = useRef(false)

  useEffect(() => {
    if (!brazeIsLoadedRef.current) {
      import(
        /* webpackExports: ["initialize", "openSession"] */
        '@braze/web-sdk'
      ).then(({ initialize, openSession }) => {
        brazeIsLoadedRef.current = true
        initialize(process.env.NEXT_PUBLIC_BRAZE_KEY || '', {
          // enableLogging: true,
          baseUrl: process.env.NEXT_PUBLIC_BRAZE_ENDPOINT || '',
        })
        openSession()
      })
    }
  }, [])

  useEffect(() => {
    if (user?.id) {
      import(
        /* webpackExports: ["changeUser", "getUser"] */
        '@braze/web-sdk'
      ).then(({ changeUser, getUser }) => {
        changeUser(user.id)
        getUser()?.setEmail(user.email)
        getUser()?.setPhoneNumber(user.phone)
      })
    }
  }, [user?.id])

  function publishBrazeEvent(
    eventName: string,
    properties?: BrazeEventProperties
  ): void {
    import(
      /* webpackExports: ["logCustomEvent"] */
      '@braze/web-sdk'
    ).then(({ logCustomEvent }) => {
      logCustomEvent(eventName, properties)
    })
  }

  return (
    <BrazeContext.Provider value={{ publishBrazeEvent }}>
      {children}
    </BrazeContext.Provider>
  )
}
